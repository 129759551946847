import { ReactElement } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cva, VariantProps } from 'class-variance-authority';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const styles = cva('relative -z-10 w-full object-cover object-center', {
  variants: {
    page: {
      home: 'sm:h-[850px] h-[419px] object-left top-[83px]',
      faq: 'h-[317px]',
      plan: 'min-[748px]:h-[511px] h-[167px]',
      proposal: 'min-[748px]:h-[511px] h-[167px]',
    },
  },
  defaultVariants: {
    page: 'home',
  },
});

interface Props extends VariantProps<typeof styles> {
  imgSource?: string;
}

export function BannerImage({ page, imgSource }: Props): ReactElement {
  return (
    <>
      {page == 'home' ? (
        <Swiper
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            enabled: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {/* <SwiperSlide>
            <img
              src={imgSource ?? '/assets/images/informativo.png'}
              alt="Banner"
              className={styles({ page })}
            />
          </SwiperSlide> */}
          <SwiperSlide>
            <img
              src={imgSource ?? '/assets/images/home-banner.png'}
              alt="Banner"
              className={styles({ page })}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={imgSource ?? '/assets/images/home-banner2.png'}
              alt="Banner"
              className={styles({ page })}
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={imgSource ?? '/assets/images/home-banner3.png'}
              alt="Banner"
              className={styles({ page })}
            />
          </SwiperSlide>
        </Swiper>
      ) : (
        <div>
          <img
            src={imgSource ?? '/assets/images/home-banner.png'}
            alt="Banner"
            className={styles({ page })}
          />
        </div>
      )}
    </>
  );
}
