import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { RootLayout } from '../components/RootLayout';
import BeneficiaryPage from '../pages/Beneficiary';
import CompaniesPage from '../pages/Companies';
import { CredentialNetPage } from '../pages/CredentialNetPage';
import DentistPage from '../pages/Dentist';
import { ErrorPage } from '../pages/ErrorPage';
import { FAQPage } from '../pages/FAQ';
import { HirePlanPage } from '../pages/HirePlan';
import { HirePlanGDFPage } from '../pages/HirePlanGDF';
import { UpdateDependentGDFPage } from '../pages/UpdateDependentGDF';
import { HomePage } from '../pages/Home';
import OuvidoriaPage from '../pages/Ouvidoria';
import { ProposalPage } from '../pages/Proposal';
import Transparency from '../pages/Transparency';
import GaleriaRedirect from '../pages/redirecionamento-Galeria';
import UnafiscoRedirect from '../pages/redirecionamento-unafisco';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
      <Route path="/" element={<HomePage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/ouvidoria" element={<OuvidoriaPage />} />
      <Route path="/transparencia" element={<Transparency />} />
      <Route path="/dentist" element={<DentistPage />} />
      <Route path="/beneficiario" element={<BeneficiaryPage />} />
      <Route path="/empresas" element={<CompaniesPage />} />
      <Route path="/contratar-plano" element={<HirePlanPage />} />
      <Route path="/galeria2025" element={<GaleriaRedirect />} />
      <Route
        path="/unafisco/carteirinhadigital"
        element={<UnafiscoRedirect />}
      />
      <Route
        path={process.env.REACT_APP_REDE_CREDENCIADA}
        element={<CredentialNetPage />}
      />
      <Route
        element={<HirePlanPage />}
        path="/contratar-plano/:token"
        id="token"
        loader={async ({ params }) => {
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_INDIVIDUAL}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        element={<HirePlanPage />}
        path="empresa/contratar-plano/:token"
        id="token-empresa"
        loader={async ({ params }) => {
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_COMPANY}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        element={<HirePlanGDFPage />}
        path="servidor/contratar-plano/:token"
        id="token-servidor"
        loader={async ({ params }) => {
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_SERVER}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route
        element={<UpdateDependentGDFPage />}
        path="servidor/incluir-dependente/:token"
        id="token-dependente"
        loader={async ({ params }) => {
          const data = await fetch(
            `${process.env.REACT_APP_OG_GET_PLAN_DETAILS_SERVER}${params.token}`,
          );
          if (data.status === 500 || data.status === 404) {
            throw new Response('Not Found', { status: 404 });
          }
          return data;
        }}
        errorElement={<ErrorPage />}
      />
      <Route path="/proposta" element={<ProposalPage />} />
    </Route>,
  ),
);
