import { ReactElement, useEffect, useState } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { useBoostedPlanDataStore } from '../../../stores/useBoostedPlanDataStore';
import { useNortheastPlanDataStore } from '../../../stores/useNortheastPlanDataStore';
import { usePlanLoadingStore } from '../../../stores/usePlanLoadingStore';
import PlanLoading from '../../icons/PlanLoading';

export function MainPurchaseSummaryContent(): ReactElement {
  const individualPlanData: any = useRouteLoaderData('token');
  const bussinessPlanData: any = useRouteLoaderData('token-empresa');
  const GDFPlanData: any = useRouteLoaderData('token-servidor');
  let initialPlanData = individualPlanData;
  if (individualPlanData != undefined) {
    initialPlanData = individualPlanData;
  } else if (bussinessPlanData != undefined) {
    initialPlanData = bussinessPlanData;
  } else {
    initialPlanData = GDFPlanData;
  }
  const boostedPlanData = useBoostedPlanDataStore(state => state.data);
  const northeastPlanData = useNortheastPlanDataStore(state => state.data);

  const [data, setData] = useState(initialPlanData);

  useEffect(() => {
    if (Object.keys(boostedPlanData).length !== 0 && boostedPlanData != data) {
      //data = newPlanData;
      setData(boostedPlanData);
      console.log(data);
    } else if (
      Object.keys(northeastPlanData).length !== 0 &&
      northeastPlanData != data
    ) {
      setData(northeastPlanData);
    }
  }, [boostedPlanData, northeastPlanData]);

  const loading = usePlanLoadingStore(state => state.loading);

  return (
    //TODO Mostrar mensagem que o plano foi trocado - planBoost
    <>
      <h3 className="text-high-emphasis text-xl">Resumo da compra</h3>
      <span className="flex justify-between uppercase text-medium-emphasis text-xs font-normal">
        <span className="block">
          <span className="block">Plano escolhido:</span>
          <strong className="block text-neutral-600 normal-case text-sm font-normal">
            {data.produtoComercial.nm_prodcomerc}
          </strong>
        </span>
        <span className="block">
          <span className="block">Vidas:</span>
          <strong className="block text-neutral-600 normal-case text-sm font-normal">
            {'1'}
            {
              //TODO pegar numero de vidas dinamico
            }
          </strong>
        </span>
      </span>
      {/* <span className="uppercase text-medium-emphasis text-xs font-normal block">
        <span className="block mb-1">Valor plano:</span>
        <div className="w-[95px] h-5 bg-gradient-to-r from-[#EBE8E8] to-[#FAFAFA] rounded-xl" />
      </span> */}
      {loading ? (
        <PlanLoading />
      ) : (
        <div className="h-[95px] bg-[#F8F8FB] py-3 flex flex-col justify-center items-center ">
          <span className="uppercase text-secondary-main rounded-xl block mb-[11px]">
            Valor total
          </span>
          {/* <span className="text-[0.625rem] mb-1">A partir de</span> */}
          <strong className="font-normal text-base text-high-emphasis">
            R$
            <span className="text-[2rem] font-semibold">
              {
                //TODO pegar valor da forma de pagamento selecionada e validar se for boleto nu_pagamento_unico
              }
              {data.formasPagamento[0].vl_valor.replace('.', ',')}
            </span>
            por mês
          </strong>
        </div>
      )}
      <div className="">
        <span className="text-[0.625rem] text-[#777777]">Carência</span>
        {data.carencias.map((carencias: any) => (
          <p
            key={carencias.carencia.nm_descricaocarencia}
            className="text-xs text-[#394146]"
          >
            {carencias.carencia.nm_descricaocarencia}
          </p>
        ))}
      </div>
    </>
  );
}
