import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  creditCardSchema,
  CreditCardSchema,
} from '../components/HirePlanForm/data/schema GDF/creditCardSchema';
import {
  debitSchema,
  DebitSchema,
} from '../components/HirePlanForm/data/schema GDF/debitSchema';
import {
  billetSchema,
  BilletSchema,
} from '../components/HirePlanForm/data/schema GDF/billetSchema';

import { useHirePlanFormStepStore } from '../stores/useHirePlanFormStepStore';
import { usePaymentStore, useValorTotalStore } from '../stores/usePaymentStore';
import { useBoostedPlanDataStore } from '../stores/useBoostedPlanDataStore';
import { useLoadingStore } from '../stores/useLoadingStore';
import { useError } from '../stores/useError';
import { useResponseStore } from '../stores/useResponseStore';
import { useVidaCountStore } from '../stores/useQuantidadeVidas';

type PaymentFormSchema = CreditCardSchema & DebitSchema & BilletSchema;

interface HirePlanFormContextProps {
  register: UseFormRegister<PaymentFormSchema>;
  control: Control<PaymentFormSchema, any>;
  watch: UseFormWatch<PaymentFormSchema>;
  handleSubmit: UseFormHandleSubmit<PaymentFormSchema>;
  onSubmit: (schema: PaymentFormSchema) => void;
  goToNextForm: (schema: PaymentFormSchema) => void;
  errors: FieldErrors<PaymentFormSchema>;
  clearErrors: UseFormClearErrors<PaymentFormSchema>;
  unregister: UseFormUnregister<PaymentFormSchema>;
  setValue: UseFormSetValue<PaymentFormSchema>;
  navigateToProposta: () => void;
}

const HirePlanFormContext = createContext({} as HirePlanFormContextProps);

interface HirePlanFormProviderProps {
  children: ReactNode;
}

export function HirePlanFormProvider({
  children,
}: HirePlanFormProviderProps): ReactElement {
  const initialPlanData = useBoostedPlanDataStore(state => state.data);
  const goToNextStep = useHirePlanFormStepStore(state => state.goToNextStep);
  const paymentId = usePaymentStore(state => state.id);
  const navigate = useNavigate();
  const storedResponse = useResponseStore(x => x.response);
  const vidas = useVidaCountStore(x => x.count);
  const valorTotal = useValorTotalStore(x => x.valor);

  const setLoading = useLoadingStore(state => state.setLoading);
  const setError = useError(state => state.setError);
  const setTitle = useError(state => state.setErrorTitle);
  const setText = useError(state => state.setErrorText);
  const setResponse = useResponseStore(x => x.setResponse);

  interface CheckoutData {
    token: string;
    cpf?: string;
    verifica: boolean;
    nomeTitular?: string;
    dataNascimento?: string;
    emailTitular?: string;
    cep?: string;
    idUf?: string;
    endereco?: string;
    numeroCasa?: string;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    celular?: string;
    nomeMae?: string;
    matricula?: string;
    idSexo?: string;
    idEstadoCivil?: string;
    formaPagamento?: object;
    proposta: string;
    valor_Mensalidade: number;
    vencimentoBoleto?: string;
    vencimentoDebito?: string;
    vencimentoConsignado?: string;
    qtdVidas: string;
    dependentes?: object;
    dependenteSamePlan: boolean;
    idBanco?: string;
    conta?: string;
    agencia?: string;
    operacao?: string;
    primeiraBoleto?: boolean;
    responsavelFinanceiro: object;
    idOrgaoExpedidor: number;
    idOrgaoExpedidorUf: number;
    idFontePagadora: number;
    orgao: number;
  }

  function getSchemaResolver() {
    switch (paymentId) {
      //Credit Card
      case 1: {
        return creditCardSchema;
      }

      case 2: {
        //Debit
        return billetSchema;
      }

      case 3: {
        //Billet
        return debitSchema;
      }

      default: {
        return creditCardSchema;
      }
    }
  }

  function removeUnusedMethod(checkoutData: CheckoutData) {
    switch (paymentId) {
      //Credit Card
      case 1: {
        return checkoutData;
      }

      case 2: {
        //Debit
        delete checkoutData['cartaoCredito'];
        return checkoutData;
      }

      case 3: {
        //Billet
        delete checkoutData['cartaoCredito'];
        return checkoutData;
      }
    }
  }

  const calculateDueDate = (): string => {
    const today = new Date();
    today.setDate(1);
    today.setMonth(today.getMonth() + 1);

    return today.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  function getPaymentData(data: PaymentFormSchema) {
    switch (paymentId) {
      case 1: {
        //Credit Card
        return {
          formaPagamento: {
            gpPagto: 1,
            idPagto: 1,
          },
        };
      }
      case 2: {
        //Debit
        console.log(data.payment);
        return {
          formaPagamento: {
            gpPagto: 2,
            idPagto: 6, //TODO: pegar id dinamico
          },
        };
      }
      case 3: {
        //Billet
        return {
          formaPagamento: {
            gpPagto: 3,
            idPagto: 4,
          },
        };
      }
    }
  }

  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<DebitSchema>({
    defaultValues: {
      payment: {
        firstPaymentBillet: false,
      },
    },
    resolver: zodResolver(getSchemaResolver()),
  });

  const goToNextForm: SubmitHandler<PaymentFormSchema> = () => {
    goToNextStep();
  };

  const onSubmit: SubmitHandler<PaymentFormSchema> = async data => {
    console.log(data);
    const banco = watch('payment.bank');
    const conta = watch('payment.debitAcount');
    const agencia = watch('payment.agency');
    const operacao = watch('payment.operation');
    const primeiraBoleto = watch('payment.firstPaymentBillet');
    const vencimentoBoleto = watch('payment.billetDate');
    const vencimentoDebito = watch('payment.debitDate');
    const VencimentoGdf = calculateDueDate();
    const reembolso = data.user?.plano == '993' ? 29.7 : 30;
    const payment = {
      formaPagamento: {
        gpPagto: 4,
        idPagto: 3,
      },
    };

    const financialResponsible = {
      responsavelFinanceiro: {
        cpf: data.user?.documents?.cpf?.replace(
          /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
          '$1$2$3$4',
        ),
        nome: data.user?.fullName,
        dataNascimento: data.user?.birthDate,
        email: data.user?.email,
        cep: data.user?.address?.cep,
        endereco: data.user?.address?.street,
        cidade: data.user?.address?.city,
        numero: data.user?.address?.number,
        complemento: data.user?.address?.complement,
        bairro: data.user?.address?.neighborhood,
        idUf: data.user?.address?.uf,
        telefone: data.user?.phone,
      },
    };
    let matricula = data.user?.documents?.matricula?.replace(/[.-]/g, '') || '';
    matricula = matricula.padStart(8, '0');
    const checkoutData: CheckoutData = {
      token: initialPlanData.token,
      cpf: data.user?.documents?.cpf?.replace(
        /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
        '$1$2$3$4',
      ),
      verifica: false,
      nomeTitular: data.user?.fullName,
      dataNascimento: data.user?.birthDate,
      emailTitular: data.user?.email,
      cep: data.user?.address?.cep,
      idUf: data.user?.address?.uf,
      endereco: data.user?.address?.street,
      numeroCasa: data.user?.address?.number,
      complemento: data.user?.address?.complement,
      bairro: data.user?.address?.neighborhood,
      cidade: data.user?.address?.city,
      celular: data.user?.phone,
      nomeMae: data.user?.fullMothersName,
      matricula: matricula,
      idSexo: data.user?.gender,
      idEstadoCivil: data.user?.civilStatus,
      idFontePagadora: 5,
      idOrgaoExpedidor: 6,
      idOrgaoExpedidorUf: 8,
      orgao: parseFloat(data.user?.orgao || '0'),
      formaPagamento: payment?.formaPagamento,
      proposta: String(Math.floor(Math.random() * 1000000000)),
      valor_Mensalidade: parseFloat(valorTotal.replace(',', '.')) + reembolso,
      vencimentoBoleto: vencimentoBoleto,
      vencimentoDebito: vencimentoDebito,
      vencimentoConsignado: VencimentoGdf,
      qtdVidas: vidas,
      dependenteSamePlan: false,
      dependentes: data.user?.dependents?.map(dependent => ({
        nome: dependent.name,
        cpf: dependent.cpf?.replace(
          /(\d{3}).(\d{3}).(\d{3})-(\d{1,2})/g,
          '$1$2$3$4',
        ),
        dataNascimento: dependent.birthDate,
        nomeMae: dependent.mothersName,
        idSexo: dependent.gender,
        idParentesco:
          dependent.parentage == '10' || dependent.parentage == '3' ? 3 : 4,
        idOrgaoExpedidor: 6,
        idOrgaoExpedidorUf: 8,
      })),
      idBanco: banco,
      conta: conta,
      agencia: agencia,
      operacao: operacao,
      primeiraBoleto: false,
      responsavelFinanceiro: financialResponsible?.responsavelFinanceiro,
    };
    setLoading(true);

    const planValue = await fetch(
      `${process.env.REACT_APP_OG_PAYMENT_INDIVIDUAL}`,
      {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(removeUnusedMethod(checkoutData)),
      },
    )
      .then(async response => {
        if (response.ok) {
          const responseData = await response.json();
          setLoading(false);
          setError(false);
          setResponse(responseData);
          goToNextStep();
          return responseData;
        }
        const errorData = await response.json();
        if (errorData.code) {
          setTitle(errorData.code);
          setText(errorData.error);
          throw new Error(errorData.error);
        } else {
          setText(errorData.message);
          throw new Error(errorData.message);
        }
      })
      .catch(error => {
        setLoading(false);
        setError(true);
      });
  };

  const navigateToProposta = () => {
    if (storedResponse) {
      navigate('/proposta', { state: storedResponse });
    }
  };

  return (
    <HirePlanFormContext.Provider
      value={{
        register,
        control,
        handleSubmit,
        watch,
        onSubmit,
        goToNextForm,
        errors,
        clearErrors,
        unregister,
        setValue,
        navigateToProposta,
      }}
    >
      {children}
    </HirePlanFormContext.Provider>
  );
}

export function useHirePlanForm(): HirePlanFormContextProps {
  return useContext(HirePlanFormContext);
}
