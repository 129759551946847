import React, { useEffect } from 'react';

const SiteBlindadoSeal: React.FC = () => {
  useEffect(() => {
    if (!document.getElementById('site-blindado-script')) {
      if (!document.getElementById('armored_website')) {
        const div = document.createElement('div');
        div.id = 'armored_website';

        const preloadParam = document.createElement('param');
        preloadParam.id = 'aw_preload';
        preloadParam.setAttribute('value', 'true');

        const cdnParam = document.createElement('param');
        cdnParam.id = 'aw_use_cdn';
        cdnParam.setAttribute('value', 'true');

        div.appendChild(preloadParam);
        div.appendChild(cdnParam);

        document.body.appendChild(div);
      }

      const script = document.createElement('script');
      script.src = '//cdn.siteblindado.com/aw.js';
      script.type = 'text/javascript';
      script.id = 'site-blindado-script';
      document.body.appendChild(script);
    }
  }, []);

  return null;
};

export default SiteBlindadoSeal;
