import { createContext, ReactElement, ReactNode, useContext } from 'react';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  SubmitHandler,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';

import {
  schema,
  Schema,
} from '../components/HirePlanForm/data/schemaDependent';

import { useHirePlanFormStepStore } from '../stores/useHirePlanFormStepStore';
import { usePaymentStore, useValorTotalStore } from '../stores/usePaymentStore';
import { useBoostedPlanDataStore } from '../stores/useBoostedPlanDataStore';
import { useLoadingStore } from '../stores/useLoadingStore';
import { useError } from '../stores/useError';
import { useResponseStore } from '../stores/useResponseStore';
import { useVidaCountStore } from '../stores/useQuantidadeVidas';

type PaymentFormSchema = Schema;

interface HirePlanFormContextProps {
  register: UseFormRegister<PaymentFormSchema>;
  control: Control<PaymentFormSchema, any>;
  watch: UseFormWatch<PaymentFormSchema>;
  handleSubmit: UseFormHandleSubmit<PaymentFormSchema>;
  onSubmit: (schema: PaymentFormSchema) => void;
  goToNextForm: (schema: PaymentFormSchema) => void;
  errors: FieldErrors<PaymentFormSchema>;
  clearErrors: UseFormClearErrors<PaymentFormSchema>;
  unregister: UseFormUnregister<PaymentFormSchema>;
  setValue: UseFormSetValue<PaymentFormSchema>;
  navigateToProposta: () => void;
}

const HirePlanFormContext = createContext({} as HirePlanFormContextProps);

interface HirePlanFormProviderProps {
  children: ReactNode;
}

export function UpdateDependentProvider({
  children,
}: HirePlanFormProviderProps): ReactElement {
  const initialPlanData = useBoostedPlanDataStore(state => state.data);
  const goToNextStep = useHirePlanFormStepStore(state => state.goToNextStep);
  //const paymentId = usePaymentStore(state => state.id);
  const navigate = useNavigate();
  const storedResponse = useResponseStore(x => x.response);
  //const vidas = useVidaCountStore(x => x.count);
  //const valorTotal = useValorTotalStore(x => x.valor);

  const setLoading = useLoadingStore(state => state.setLoading);
  const setError = useError(state => state.setError);
  const setTitle = useError(state => state.setErrorTitle);
  const setText = useError(state => state.setErrorText);
  const setResponse = useResponseStore(x => x.setResponse);

  interface CheckoutData {
    token: string;
    cpf?: string;
    verifica: boolean;
    nomeTitular?: string;
    dataNascimento?: string;
    emailTitular?: string;
    cep?: string;
    idUf?: string;
    endereco?: string;
    numeroCasa?: string;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    celular?: string;
    nomeMae?: string;
    matricula?: string;
    idSexo?: string;
    idEstadoCivil?: string;
    formaPagamento?: object;
    proposta: string;
    valor_Mensalidade: number;
    vencimentoBoleto?: string;
    vencimentoDebito?: string;
    vencimentoConsignado?: string;
    qtdVidas: string;
    dependentes?: object;
    dependenteSamePlan: boolean;
    idBanco?: string;
    conta?: string;
    agencia?: string;
    operacao?: string;
    primeiraBoleto?: boolean;
    responsavelFinanceiro: object;
    idOrgaoExpedidor: number;
    idOrgaoExpedidorUf: number;
    idFontePagadora: number;
    orgao: number;
  }

  const calculateDueDate = (): string => {
    const today = new Date();
    today.setMonth(today.getMonth() + 1);

    today.setDate(1);

    return today.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };
  const getMonthAndYear = (): string => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 2);

    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    return `${year}${month}`;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    unregister,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<PaymentFormSchema>({
    resolver: zodResolver(schema),
  });

  const goToNextForm: SubmitHandler<PaymentFormSchema> = () => {
    goToNextStep();
  };

  const onSubmit: SubmitHandler<PaymentFormSchema> = async data => {
    console.log(data);
    const adesao = calculateDueDate();
    const MonthYear = getMonthAndYear();
    const formatDate = (date: string) => {
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    };
    const token = process.env.REACT_APP_TOKEN_S4E_V2;

    const newData = {
      token: token,
      dados: {
        parceiro: {
          codigo: initialPlanData.vendedorS4eId,
          adesionista: 0,
        },
        responsavelFinanceiro: {
          codigo: data.Associado.id,
          dataAssinaturaContrato: formatDate(adesao),
        },
        dependente: data.dependents?.map(dependent => ({
          tipo:
            dependent.parentage == '10' || dependent.parentage == '3' ? 3 : 4,
          nome: dependent.name,
          cpf: dependent.cpf?.replace(/[.-]/g, ''),
          sexo: dependent.gender == '1' ? 0 : 1,
          plano: dependent.plano
            ? parseFloat(dependent.plano)
            : data.Associado.plano,
          planoValor: dependent.plano_vl
            ? dependent.plano_vl.replace(',', '.')
            : data?.Associado?.plano_vl?.replace(',', '.'),
          numeroProposta: '',
          carenciaAtendimento: 0,
          rcaId: 0,
          cd_orientacao_sexual: 0,
          OutraOrientacaoSexual: '',
          cd_ident_genero: 0,
          OutraIdentidadeGenero: '',
          idExterno: '',
          nomeMae: dependent.mothersName,
          MMYYYY1Pagamento: MonthYear,
          numeroCarteira: '',
          observacaoUsuario: '',
          dataNascimento: formatDate(dependent.birthDate),
          funcionarioCadastro: 0,
          dataCadastroLoteContrato: '',
        })),
        contatoDependente: [],
      },
    };
    setLoading(true);

    const planValue = await fetch(
      `${process.env.REACT_APP_OG_POST_INCLUSAO_DEPENDENTE}`,
      {
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(newData),
      },
    )
      .then(async response => {
        if (response.ok) {
          const responseData = await response.json();
          setLoading(false);
          setError(false);
          setResponse(responseData);
          goToNextStep();
          return responseData;
        }
        const errorData = await response.json();

        if (errorData.code) {
          setTitle(errorData.code);
          setText(errorData.error);
          throw new Error(errorData.error);
        } else {
          setText(errorData.message);
          throw new Error(errorData.message);
        }
      })
      .then(response => navigate('/proposta', { state: response }))
      .catch(error => {
        setLoading(false);
        setError(true);
      });
  };

  const navigateToProposta = () => {
    if (storedResponse) {
      navigate('/proposta', { state: storedResponse });
    }
  };

  return (
    <HirePlanFormContext.Provider
      value={{
        register,
        control,
        handleSubmit,
        watch,
        onSubmit,
        goToNextForm,
        errors,
        clearErrors,
        unregister,
        setValue,
        navigateToProposta,
      }}
    >
      {children}
    </HirePlanFormContext.Provider>
  );
}

export function useUpdateDependentForm(): HirePlanFormContextProps {
  return useContext(HirePlanFormContext);
}
