type Data = {
  Sigla: string;
  Nome: string;
  Id: number;
  sigla: string;
  id_uf: number;
  nm_sexo: string;
  id_sexo: number;
  nm_orgaoexpedidor: string;
  id_oe: number;
  nm_estado_civil: string;
  id_estado_civil: number;
  nm_siglagraupar: string;
  id_parentesco: number;
  id_orgao: number;
  tx_nm_orgao: string;
};

export const changeUF = (ufs: Data[]): void => {
  const returnData = ufs.map(data => {
    data.Sigla = data.sigla;
    data.Id = data.id_uf;
  });
};
export const changeGender = (genders: Data[]): void => {
  const returnData = genders.map(data => {
    data.Nome = data.nm_sexo;
    data.Id = data.id_sexo;
  });
};
export const changeIssuingAgency = (issuingAgencies: Data[]): void => {
  const returnData = issuingAgencies.map(data => {
    data.Nome = data.nm_orgaoexpedidor;
    data.Id = data.id_oe;
  });
};
export const changeCivilStatus = (civilStatus: Data[]): void => {
  const returnData = civilStatus.map(data => {
    data.Nome = data.nm_estado_civil;
    data.Id = data.id_estado_civil;
  });
};
export const changeParentage = (parentage: Data[]): void => {
  const returnData = parentage.map(data => {
    data.Sigla = data.nm_siglagraupar;
    data.Id = data.id_parentesco;
  });
};
export const changeParentageGDF = (parentage: Data[]): Data[] => {
  const returnData = parentage
    .filter(data => data.id_parentesco == 10 || data.id_parentesco == 11)
    .map(data => {
      return {
        ...data,
        Sigla: data.nm_siglagraupar,
        Id: data.id_parentesco,
      };
    });
  return returnData;
};
export const changeOrgao = (orgao: Data[]): Data[] => {
  const returnData = orgao
    .filter(
      data =>
        data.id_orgao !== 93 && data.id_orgao !== 54 && data.id_orgao !== 57,
    )
    .map(data => {
      return {
        ...data,
        Nome: data.tx_nm_orgao,
        Id: data.id_orgao,
      };
    });
  return returnData;
};
