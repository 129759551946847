import React, { ReactElement, useEffect, useState } from 'react';

import * as z from 'zod';

import { useHirePlanForm } from '../../../../../contexts/HirePlanFormProviderGdf';
import { useDependentCountStore } from '../../../../../stores/useDependentCountStore';
import { useHirePlanFormStepStore } from '../../../../../stores/useHirePlanFormStepStore';
import { Button } from '../../../../Button';
import { PlusIcon } from '../../../../icons/PlusIcon';
import { dependentsSchema } from '../../../data/schema/dependentsSchema';

import { validateCEP } from '../../../../../utils/ValidateCEP';
import {
  changeCivilStatus,
  changeGender,
  changeUF,
  changeOrgao,
  changeParentage,
} from '../../../../../utils/ChangeArrays';
import { GoForwardButton } from '../../../GoForwardButton';
import { DependentCard } from './DependentCard';
import { DependentForm } from './DependentFormTeste';
import { Input } from '../../../../Input';
import { Select } from '../../../../Select';
import { useRouteLoaderData } from 'react-router-dom';
import { useBoostedPlanDataStore } from '../../../../../stores/useBoostedPlanDataStore';
import { usePlanLoadingStore } from '../../../../../stores/usePlanLoadingStore';
import {
  useDependentIdStore,
  useDependentOpenModalDataStore,
  useDependentValueStore,
} from '../../../../../stores/useDependentStore';
import { useVidaCountStore } from '../../../../../stores/useQuantidadeVidas';

function validateDependent(obj: any): boolean {
  try {
    dependentsSchema.parse(obj);
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      return false;
    }
    return false;
  }
}

export function BeneficiaryForm(): ReactElement {
  const goToNextStep = useHirePlanFormStepStore(state => state.goToNextStep);
  const data: any = useRouteLoaderData('token-servidor');
  const { watch, setValue, errors, register, goToNextForm, handleSubmit } =
    useHirePlanForm();
  const setBoostedPlanData = useBoostedPlanDataStore(
    state => state.setBoostPlanData,
  );
  const valorDependentes = useDependentValueStore(x => x.setValorTotal);
  const [orgaos, setOrgaos] = useState([]);
  const [listOrgaos, setListOrgaos]: any = useState([]);
  const setLoading = usePlanLoadingStore(state => state.setLoading);
  const [hasDependentes, setHasDependentes] = useState(false);
  const countVida = useVidaCountStore(x => x.count);
  const setId = useDependentIdStore(state => state.setId);

  const CEP = watch('user.address.cep');
  const city = watch('user.address.city');
  const neighborhood = watch('user.address.neighborhood');
  const street = watch('user.address.street');
  const plano = watch('user.plano');
  const dependentes = watch('user.dependents');

  const Planos = [
    {
      Id: 993,
      Nome: 'OdontoClinico - apenas R$ 0,30 para o Titular',
      Tipo: 'OdontoClinico',
    },
    {
      Id: 998,
      Nome: 'OdontoDoc - apenas R$ 2,90 para o Titular',
      Tipo: 'OdontoDoc',
    },
    {
      Id: 999,
      Nome: 'OdontoProtese - apenas R$ 59,90 para o Titular',
      Tipo: 'OdontoProtese',
    },
    {
      Id: 1000,
      Nome: 'OdontoOrto - apenas R$ 80,00 para o Titular',
      Tipo: 'OdontoOrto',
    },
  ];

  useEffect(() => {
    console.log('dependentes: ', dependentes);
    if (dependentes && countVida > 1 && dependentes[0].cpf) {
      setHasDependentes(true);
      const Total = calcularDependenteVlTotal(dependentes || []);
      valorDependentes(Total);
    } else {
      setHasDependentes(false);
      valorDependentes(0);
    }
  }, [countVida]);
  const setDependentOpenModal = useDependentOpenModalDataStore(
    x => x.setdependentOpenModalData,
  );

  const getCEPDetails = async (e: string) => {
    const CEPInformations = await fetch(
      `${process.env.REACT_APP_VIACEP}${e}/json/`,
    ).then(response => response.json());
    if (CEPInformations.status === 500 || CEPInformations.status === 404) {
      throw new Response('Not Found', { status: 404 });
    }
    const UF = data.listaUFS.find(x => x.sigla == CEPInformations.uf).Id;
    setValue('user.address.city', CEPInformations.localidade);
    setValue('user.address.neighborhood', CEPInformations.bairro);
    setValue('user.address.street', CEPInformations.logradouro);
    setValue('user.address.uf', String(UF));
    return CEPInformations;
  };

  const fetchOrgaoFP = async id => {
    const urlBaseApiV2 = process.env.REACT_APP_OG_API_URL;
    const response = await fetch(`${urlBaseApiV2}/info/orgaoFP?id=${id}`);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    setOrgaos(data);
  };

  const fetchPlan = async (planoId, VendedorId) => {
    const urlBaseApiV2 = process.env.REACT_APP_OG_API_URL;
    if (planoId && VendedorId) {
      const response = await fetch(`
        ${urlBaseApiV2}/info/getPlansSeller?vendedorId=${VendedorId}&produtoComercial=${planoId}`);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const urlPlan = process.env.REACT_APP_OG_GET_PLAN_DETAILS_SERVER;
      const Plan = await fetch(`${urlPlan}${data.cd_codtokenidparc}`);

      const retorno = await Plan.json();
      console.log('retornou: ', retorno);
      setBoostedPlanData(retorno);
      setLoading(false);
    }
  };

  function handleValidate() {
    if (dependentes && dependentes[0] && dependentes[0].cpf == '') {
      removeClick(0);
    }
    handleSubmit(goToNextForm)();
  }

  useEffect(() => {
    console.log('erros :', errors);
    const dados = watch('valida.validation.retorno');
    const matricula = watch('valida.validation.matricula');
    setValue('user.documents.cpf', dados?.cpf);
    setValue('user.documents.matricula', matricula);
    setValue('user.fullName', dados?.nome ? dados.nome : '');
    setValue(
      'user.gender',
      dados?.sexo ? (dados?.sexo == 'M' ? '2' : '1') : '',
    );
    setValue('user.birthDate', dados?.data_nasc ? dados?.data_nasc : '');
    setValue('user.fullMothersName', dados?.nome_mae ? dados?.nome_mae : '');
    setValue('user.email', dados?.email ? dados?.email : '');
    setValue('user.phone', dados?.telefone ? dados?.telefone : '');
    setValue('user.address.cep', dados?.cep ? dados.cep : '');
    setValue('user.address.number', dados?.numero ? String(dados?.numero) : '');
    setValue(
      'user.address.complement',
      dados?.complemento ? dados.complemento : '',
    );

    if (dados?.cep) {
      getCEPDetails(dados?.cep);
    }
    fetchOrgaoFP(5);
  }, []);

  useEffect(() => {
    if (CEP && validateCEP.test(CEP)) {
      getCEPDetails(CEP);
    }
  }, [CEP]);

  useEffect(() => {
    fetchPlan(plano, data.vendedorId);
  }, [plano]);

  useEffect(() => {
    const newOrgaos = changeOrgao(orgaos);
    setListOrgaos(newOrgaos);
  }, [orgaos]);

  const editClick = (index: number) => {
    setId(index);
    setDependentOpenModal(true);
  };

  function calcularDependenteVlTotal(dependentes: any[]): number {
    return dependentes.reduce((total, dependente) => {
      const valor = parseFloat(dependente.plano_vl.replace(',', '.'));
      return total + (isNaN(valor) ? 0 : valor);
    }, 0);
  }

  const removeClick = (ind: number) => {
    const currentDependentes = watch('user.dependents') || [];
    const updatedDependentes = currentDependentes.filter(
      (_, index) => index !== ind,
    );
    setValue('user.dependents', updatedDependentes);
  };

  changeUF(data.listaUFS);
  changeGender(data.listaSexos);
  changeCivilStatus(data.listaEstadosCivil);
  changeParentage(data.listaParentescos);

  return (
    <div className="w-full flex flex-col items-center">
      <h2 className="min-[748px]:text-xl text-lg font-semibold text-primary-main mb-4 mt-4">
        Dados do Titular
      </h2>
      <div className="w-full min-[748px]:mt-4 rounded-xl p-2">
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-2">
            <Input
              label="Nome"
              placeholder="Nome"
              errorMessage={errors.user?.fullName?.message}
              {...register('user.fullName')}
            />
          </div>
          <div className="col-span-1">
            <Input
              label="CPF"
              placeholder="CPF"
              activateMask
              mask="999.999.999-99"
              errorMessage={errors.user?.documents?.cpf?.message}
              {...register('user.documents.cpf')}
            />
          </div>
          <div className="col-span-1">
            <Input
              activateMask
              mask="99/99/9999"
              label="Data de nascimento"
              placeholder="Data de nascimento"
              errorMessage={errors.user?.birthDate?.message}
              {...register('user.birthDate')}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6">
          <Select
            label="Sexo"
            options={[]}
            optionsFull={data.listaSexos}
            placeholder="Sexo"
            errorMessage={errors.user?.gender?.message}
            {...register('user.gender')}
          />
          <Select
            label="Estado civil"
            options={[]}
            optionsFull={data.listaEstadosCivil}
            placeholder="Estado civil"
            errorMessage={errors.user?.civilStatus?.message}
            {...register('user.civilStatus')}
          />
          <Input
            label="Telefone"
            activateMask
            mask="(99) 99999-9999"
            placeholder="Telefone"
            errorMessage={errors.user?.phone?.message}
            {...register('user.phone')}
          />
        </div>
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-2">
            <Input
              label="Nome da mãe"
              placeholder="Nome da mãe"
              errorMessage={errors.user?.fullMothersName?.message}
              {...register('user.fullMothersName')}
            />
          </div>
          <div className="col-span-2">
            <Input
              label="E-mail"
              placeholder="E-mail"
              errorMessage={errors.user?.email?.message}
              {...register('user.email')}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-1">
            <Input
              activateMask
              mask="99999-999"
              label="CEP"
              className="w-full"
              placeholder="CEP"
              errorMessage={errors.user?.address?.cep?.message}
              {...register('user.address.cep')}
            />
          </div>
          <div className="col-span-2">
            <Input
              label="Logradouro"
              value={street}
              placeholder="Digite o seu logradouro"
              errorMessage={errors.user?.address?.street?.message}
              {...register('user.address.street')}
            />
          </div>
          <Input
            label="Número"
            placeholder="Digite o seu número"
            errorMessage={errors.user?.address?.number?.message}
            {...register('user.address.number')}
          />
        </div>
        <div className="grid grid-cols-4 gap-6">
          <Input
            label="Complemento"
            placeholder="Digite o seu complemento"
            errorMessage={errors.user?.address?.complement?.message}
            {...register('user.address.complement')}
          />
          <Input
            label="Bairro"
            value={neighborhood}
            placeholder="Digite o seu bairro"
            errorMessage={errors.user?.address?.neighborhood?.message}
            {...register('user.address.neighborhood')}
          />
          <Input
            label="Cidade"
            value={city}
            placeholder="Digite sua cidade"
            errorMessage={errors.user?.address?.city?.message}
            {...register('user.address.city')}
          />
          <Select
            label="UF"
            options={[]}
            optionsFull={data.listaUFS}
            placeholder="UF"
            errorMessage={errors.user?.address?.uf?.message}
            {...register('user.address.uf')}
          />
        </div>
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-2">
            <Select
              label="Orgão"
              options={[]}
              optionsFull={listOrgaos}
              placeholder="Orgão"
              errorMessage={errors.user?.orgao?.message}
              {...register('user.orgao')}
            />
          </div>
          <div className="col-span-2">
            <Select
              label="Plano"
              options={[]}
              optionsFull={Planos}
              placeholder="Plano"
              errorMessage={errors.user?.plano?.message}
              {...register('user.plano')}
            />
          </div>
        </div>
      </div>
      <div
        className="w-full min-[748px]:mt-4 rounded-xl p-2"
        style={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          gap: '40px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2 className="min-[748px]:text-[2rem] text-2xl font-semibold text-primary-main">
          Dependentes
        </h2>
        <p className="font-normal text-secondary-main mt-1">
          Você pode adicionar quantos dependentes desejar.
        </p>
        <DependentForm />
      </div>
      {hasDependentes && (
        <div className="w-full min-[748px]:mt-4 rounded-xl p-2">
          <table width="98%" style={{ margin: '2%' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>Nome</th>
                <th style={{ textAlign: 'center' }}>CPF</th>
                <th style={{ textAlign: 'center' }}>Nascimento</th>
                <th style={{ textAlign: 'center' }}>Parentesco</th>
                <th style={{ textAlign: 'center' }}>Plano</th>
                <th style={{ textAlign: 'center' }}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {dependentes?.map((dependente, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ textAlign: 'center' }}>{dependente.name}</td>
                    <td style={{ textAlign: 'center' }}>{dependente.cpf}</td>
                    <td style={{ textAlign: 'center' }}>
                      {dependente.birthDate}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {dependente.parentage != ''
                        ? data.listaParentescos.find(
                            x => x.Id == dependente.parentage,
                          ).Sigla
                        : ''}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {Planos.find(
                        x => x.Id == parseFloat(dependente.plano || '993'),
                      )?.Tipo || 'OdontoClinico'}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <button type="button" onClick={() => editClick(index)}>
                        Editar
                      </button>
                      {' / '}
                      <button type="button" onClick={() => removeClick(index)}>
                        Remover
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <GoForwardButton addBackButton onForwardClick={handleValidate} />
    </div>
  );
}
