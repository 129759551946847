export const footerNavigation = [
  {
    name: 'Empresarial',
    link: '/empresas',
  },
  {
    name: 'Transparência',
    link: '/transparencia',
  },
  {
    name: 'Rede Credenciada',
    link: process.env.REACT_APP_REDE_CREDENCIADA ?? '/',
  },
  /* {
    name: 'Fale com a OdontoGroup',
    link: process.env.REACT_APP_FALE_COM_A_ODONTOGROUP ?? '/',
  }, */
  {
    name: 'Ouvidoria',
    link: '/ouvidoria',
  },
  {
    name: 'Institucional',
    link: '/',
  },
];
